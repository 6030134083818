module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"61","matomoUrl":"https://matomo.hosting.interness.de","siteUrl":"https://schrankstudio-schega.de","requireConsent":"true","enableJSErrorTracking":true,"dev":false},
    },{
      plugin: require('../../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"maintainCase":false,"removeAccents":true,"elements":["h2","h3"],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"maintainCase":false,"removeAccents":true,"elements":["h2","h3","h4"]}},"gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1980,"quality":85,"linkImagesToOriginal":false,"withWebp":false}},{"resolve":"gatsby-remark-external-links","options":{"target":null,"rel":"nofollow noopener noreferrer external"}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/pgegenfurtner/Projekte/interness/atacama/atacama-projects/projects/162-schega","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../../../node_modules/@interness/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-150},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://schrankstudio-schega.de"},
    },{
      plugin: require('../../../../atacama-packages/packages/web-core/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/themes/theme-sonora/src/utils/typography.js","omitGoogleFont":true},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/themes/theme-sonora/src/components/Layout/Layout.js"},
    },{
      plugin: require('../node_modules/@interness/ecommerce-addon/gatsby-browser.js'),
      options: {"plugins":[],"display_name":"","path":""},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6H6ZKK3JFZ"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"delayOnRouteUpdate":0,"origin":"https://www.googletagmanager.com"}},
    }]
